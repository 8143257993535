import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box } from "theme-ui";
import FormMatchLazy from "../components/FormMatchLazy";
import PictureSet from "../components/PictureSet";
import imgSet07 from "../imgsets/imgset07";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Matching" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet07} mdxType="PictureSet" />
    </Wide>
    <h1>{`Matching`}</h1>
    <p>{`Wenn ihr als Gründer oder Gründungsinteressierte jemanden sucht, dann könnte ihr dies in die Form eintragen. Suchen könnt ihr beispielsweise Mitgründer, Unterstützer, Leute, die euch eine Webseite programmieren wollen, Coaches, Berater, Dienstleister usw. Ihr könnt natürlich auch etwas (im Austausch) anbieten, wenn ihr wollt.`}</p>
    <p>{`Wir stellen eure Suchanfrage dann ein. Wenn ihr euch nicht erneut meldet, wird die Suche nach ca. 2 Monaten gelöscht.`}</p>
    <FormMatchLazy mdxType="FormMatchLazy" />
    <h2>{`Matching-Anfragen`}</h2>
    <p>{`Derzeit liegen keine Matching-Anfragen vor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      